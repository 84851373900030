* {
  box-sizing: border-box;
}

html,
body,
#root {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

body {
  overscroll-behavior-y: none;
  background: #f5f5f5;
  font-family: 'Banana Grotesk', sans-serif;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  color: white;
}

a {
  color: white;
}

a {
  pointer-events: all;
  color: white;
  text-decoration: none;
}

svg {
  fill: white;
}
